<template>
  <div
    class="login-nmb"
    :style="{
      'min-height': `${$screenHeight}px`,
    }"
  >
    <div class="hint">
      <div class="logo">
        <img src="@/assets/images/lbg-2.png" alt="" />
      </div>
      <div class="logo-text">
        <p>时光 · 亲友工具</p>
        <p>重新认识你我，收藏美好时光</p>
      </div>
    </div>
    <div class="bottom">
      <template v-if="code">
        <div class="authing">
          <p>授权登陆中...</p>
          <van-loading size="16" class="loading"></van-loading>
        </div>
      </template>
      <template v-else>
        <div class="login-bt">
          <div class="inner" @click="goLogin">
            <svg-icon icon-class="icon-wechat"></svg-icon>
            <p>微信登录</p>
          </div>
          <div class="policy">
            <van-checkbox class="scheckbox" v-model="policyRef" shape="square"></van-checkbox>
            我已阅读并同意<router-link to="/instructions/protocol?from=login"
              >《用户协议》</router-link
            >和<router-link to="/instructions/private?from=login">《隐私政策》</router-link>
          </div>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import Cookies from 'js-cookie';
import { setCookie, getCookie } from '../../libs/token';

export default {
  name: 'page-login',
  components: {},
  data() {
    return {
      snsapi: 'https://open.weixin.qq.com/connect/oauth2/authorize?',
      policyRef: true,
    };
  },
  computed: {
    code() {
      return this.$route.query.code;
    },
    afterLoginedGo() {
      return getCookie(this.$constant.firstInUrlKey) || '';
    },
    to() {
      return decodeURI(this.$route.query.to ?? '');
    },
    loginUrl() {
      const { appid } = this.officialAccount;
      const redirectURI = encodeURIComponent(this.officialAccount.redirectURI);
      return `${this.snsapi}appid=${appid}&redirect_uri=${redirectURI}&response_type=code&scope=snsapi_userinfo&state=STATE#wechat_redirect`;
    },
  },
  mounted() {
    console.log(this.to);
    if (this.to) {
      setCookie(this.$constant.firstInUrlKey, this.to);
    }
    if (this.code) {
      this.onLogin();
    }
  },
  methods: {
    ...mapActions(['login']),
    onLogin() {
      const that = this;
      that.authing = 'authing';
      that
        .login({
          code: that.code,
        })
        .then(() => {
          let redirect;
          if (that.afterLoginedGo) {
            redirect = that.afterLoginedGo;
            Cookies.remove(that.$constant.firstInUrlKey);
          }
          redirect = that.afterLoginedGo === undefined
            || that.afterLoginedGo === 'undefined'
            || that.afterLoginedGo === ''
            ? '/'
            : redirect;
          that.$router.replace({
            path: redirect,
            sign: 'wx-redirect',
          });
        });
    },
    goLogin() {
      if (this.policyRef) {
        window.location.href = this.loginUrl;
      } else {
        this.$notify({
          type: 'danger',
          message: '请同意用户协议',
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.login-nmb {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;

  background: url("~@/assets/images/lbg-1.png") no-repeat center top;
  background-size: contain;

  .hint {
    position: absolute;
    top: 0;
    left: 50%;
    transform: translate(-50%, 200px);
    text-align: center;

    p {
      margin-bottom: 18px;
      font-size: 24px;
    }

    .logo {
      width: 400px;
      height: 344px;
      margin: 0 auto;

      img {
        width: 100%;
        height: 100%;
      }
    }

    .logo-text {
      margin-top: 56px;
      p {
        color: #101010;
        font-size: 28px;
        line-height: 41px;
        &:first-child {
          font-size: 36px;
          line-height: 52px;
          font-weight: bold;
        }
      }
    }
  }

  .bottom {
    position: fixed;
    bottom: 230px;
    left: 0;
    right: 0;

    @include saveAreaBottom;

    .authing {
      // margin-top: 100px;
      text-align: center;
      p {
        margin-bottom: 30px;
      }

      .loading {
        text-align: center;
      }
    }

    .login-bt {
      padding: 0 125px;
      margin-top: 100px;
      & > .inner {
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: #35ad1a;
        border-radius: 10px;
        height: 50px;
        box-shadow: 0px 2px 6px 0px rgba(0, 0, 0, 0.4);
      }

      p {
        font-size: 28px;
        color: #fff;
        margin-bottom: 0;
        margin-left: 20px;
      }
    }

    .policy {
      font-size: 20px;
      color: #bbb;
      width: 100%;
      text-align: center;
      line-height: 30px;
      box-sizing: border-box;
      margin-top: 40px;
      @include flex-box;
      justify-content: center;
      align-items: center;
      a {
        color: #4386b5;
        line-height: 30px;
        display: inline-block;
      }
    }
  }
}
.scheckbox {
  width: 25px;
  height: 25px;
  overflow: hidden;
  ::v-deep .van-checkbox__icon {
    .van-icon {
      border-radius: 2px;
    }
    font-size: inherit;
    &.van-checkbox__icon--checked .van-icon {
      background-color: #101010;
      border-color: #101010;
    }
  }
}
</style>
